import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Layout/AuthLayout.vue';

import NotFound from '@/views/NotFoundPage.vue';

const routes = [
  {
    path: '/',
    redirect: 'edit-profile',
    component: DashboardLayout,
    children: [
      {
        path: '/suspend',
        name: 'suspend',
        component: () => import('../views/Suspend.vue')
      },
      {
        path: '/kyc',
        name: 'kyc',
        component: () => import('../views/Kyc.vue')
      },
      {
        path: '/deposit',
        name: 'deposit',
        component: () => import('../views/NewDeposit.vue')
      },
      {
        path: '/choose-card',
        name: 'choose-card',
        component: () => import('../views/New-Deposit/ChooseCard.vue')
      },
      {
        path: '/deposit-method',
        name: 'deposit-method',
        props:route=>({
          card_number:route.query.card_number
        }),
        component: () => import('../views/New-Deposit/DepositMethod.vue')
      },
      {
        path: '/digital-assets',
        name: 'digital-assets',
        props:route=>({
          card_number:route.query.card_number,
          network:route.query.network
        }),
        component: () => import('../views/New-Deposit/DigitalAssets.vue')
      },
      {
        path: '/alipay',
        name: 'alipay',
        props:route=>({
          card_number:route.query.card_number
        }),
        component: () => import('../views/New-Deposit/Alipay.vue')
      },
      {
        path: '/wechatpay',
        name: 'wechatpay',
        props:route=>({
          card_number:route.query.card_number
        }),
        component: () => import('../views/New-Deposit/Wechatpay.vue')
      },
      // {
      //   path: '/deposit',
      //   name: 'deposit',
      //   component: () => import('../views/Deposit.vue')
      // },
      {
        path: '/change-atm-pin',
        name: 'change-atm-pin',
        props:route=>({
          card_number:route.query.card_number
        }),
        component: () => import('../views/ChangeAtmPin.vue')
      },
      {
        path: '/card-management',
        name: 'card-management',
        component: () => import('../views/Card-Management/zero.vue')
      },
      {
        path: '/order-card',
        name: 'order-card',
        component: () => import('../views/New-Card-Management/OrderNewCard.vue')
      },
      {
        path: '/fill-in-details',
        name: 'fill-in-details',
        component: () => import('../views/New-Card-Management/ApplyInfo.vue')
      },
      {
        path: '/payment-method',
        name: 'payment-method',
        component: () => import('../views/New-Card-Management/PaymentMethod.vue')
      },
      {
        path: '/application-fee',
        name: 'application-fee',
        component: () => import('../views/New-Card-Management/ApplicationFee.vue')
      },
      {
        path: '/transfer',
        name: 'transfer',
        component: () => import('../views/Transfer/transfer.vue')
      },
      {
        path: '/transfer-choose-card',
        name: 'transfer-choose-card',
        component: () => import('../views/Transfer/chooseCard.vue')
      },
      {
        path: '/transfer-method',
        name: 'transfer-method',
        props:route=>({
          card_number:route.query.card_number
        }),
        component: () => import('../views/Transfer/transferMethod.vue')
      },
      {
        path: '/transfer-type',
        name: 'transfer-type',
        props:route=>({
          card_number:route.query.card_number
        }),
        component: () => import('../views/Transfer/transferType.vue')
      },
      {
        path: '/TT-same-name',
        name: 'TT-same-name',
        props:route=>({
          card_number:route.query.card_number
        }),
        component: () => import('../views/Transfer/TTSameName.vue')
      },
      {
        path: '/TT-third-party',
        name: 'TT-third-party',
        props:route=>({
          card_number:route.query.card_number
        }),
        component: () => import('../views/Transfer/TTThirdParty.vue')
      },
      {
        path: '/cc',
        name: 'cc',
        props:route=>({
          card_number:route.query.card_number
        }),
        component: () => import('../views/Transfer/cc.vue')
      },
      {
        path: '/summary',
        name: 'summary',
        component: () => import('../views/Transfer/summary.vue')
      },
      {
        path: '/summarycc',
        name: 'summarycc',
        component: () => import('../views/Transfer/summarycc.vue')
      },
      {
        path: '/transfer-details',
        name: 'transfer-details',
        props:route=>({
          trans_no:route.query.trans_no,
          type:route.query.type
        }),
        component: () => import('../views/Transfer/transferDetails.vue')
      },
      {
        path: '/deposit-details',
        name: 'deposit-details',
        props:route=>({
          trans_no:route.query.trans_no
        }),
        component: () => import('../views/New-Deposit/depositDetails.vue')
      },
      {
        path: '/edit-profile',
        name: 'edit-profile',
        component: () => import('../views/EditProfile.vue')
      },
      {
        path: '/system-configuration',
        name: 'system-configuration',
        component: () => import('../views/Settings/SystemConfiguration.vue')
      },  
      {
        path: '/change-password',
        name: 'change-password',
        component: () => import('../views/Settings/ChangePassword.vue')
      },
      {
        path: '/deposit-report',
        name: 'deposit-report',
        component: () => import('../views/DepositReport.vue')
      },
      {
        path: '/transfer-report',
        name: 'transfer-report',
        component: () => import('../views/TransferReport.vue')
      },
      // {
      //   path: '/account',
      //   name: 'account',
      //   component: () => import('../views/Account.vue')
      // },
      // {
      //   path: '/account-information',
      //   name: 'account-information',
      //   component: () => import('../views/Account/AccountInformation.vue')
      // },
      // {
      //   path: '/document-verification',
      //   name: 'document-verification',
      //   props:route=>({
      //     name:route.query.name,
      //     email:route.query.email,
      //     dob:route.query.dob,
      //     relation:route.query.relation,
      //     phone_code:route.query.phone_code,
      //     phone:route.query.phone
      //   }),
      //   component: () => import('../views/Account/DocumentVerification.vue')
      // },
      // {
      //   path: '/account-summary',
      //   name: 'account-summary',
      //   props:route=>({
      //     name:route.query.name,
      //     email:route.query.email,
      //     dob:route.query.dob,
      //     relation:route.query.relation,
      //     phone_code:route.query.phone_code,
      //     phone_country:route.query.phone_country,
      //     phone:route.query.phone
      //   }),
      //   component: () => import('../views/Account/AccountSummary.vue')
      // },
      {
        path: '/transaction',
        name: 'transaction',
        component: () => import('../views/Transaction.vue')
      },
      {
        path: '/cs',
        name: 'customer-service',
        component: () => import('../views/CustomerService.vue')
      },
      {
        path: '/faq',
        name: 'faq',
        component: () => import('../views/Faq.vue')
      },
      {
        path: "/logout",
        name: "logout",
        component: {
          beforeRouteEnter(to, from, next) {
            localStorage.removeItem("token");
            localStorage.removeItem("member");
            localStorage.removeItem("isFirstTime");

            location.reload();
          }
        }
      }
    ]
  },
  {
    path: '/',
    redirect: 'login',
    component: AuthLayout,
    children: [
      {
        path: '/login',
        name: 'login',
        meta:{
          noBodyBackground:true
        },
        props:route=>({
          token:route.query.verification,
          register:route.query.register
        }),
        component: () => import('../views/Auth/Login.vue')
      },
      {
        path: '/register',
        name: 'register',
        meta:{
          noBodyBackground:true
        },
        component: () => import('../views/Auth/Register.vue')
      },
      {
        path: '/forgot-password',
        name: 'forgot-password',
        meta:{
          noBodyBackground:true
        },
        component: () => import('../views/Auth/ForgotPassword.vue')
      },
      {
        path: '/resend-email',
        name: 'resend-email',
        meta:{
          noBodyBackground:true
        },
        component: () => import('../views/Auth/ResendEmail.vue')
      },
      {
        path: '/term-condition',
        name: 'term-condition',
        meta:{
          noBodyBackground:true
        },
        component: () => import('../views/TermCondition.vue')
      },
      {
        path: '/payment',
        name: 'payment',
        meta:{
          noBodyBackground:true
        },
        component: () => import('../views/PendingPayment.vue')
      },
      { path: '*', component: NotFound },
    ]
  }
];

export default routes;
