<template>
  <div class="wrapper" @click="clickOutSide">
    
    <side-bar>
      <template slot="topLogo">
        <span class="rounded-circle d-flex flex-column align-items-center">
          <img alt="Image placeholder" :src="`${publicPath}/img/rcp/logo.svg`">
        </span>
      </template>

      <template slot="links">
        <sidebar-item
          v-if="isFirstTime == 'false' && kycApprove && haveCard && !profileStatus && domain_info.merchant_deposit != 1"
          :link="{
            name: 'Deposit',
            path: '/deposit',
            imageicon: 'finance-management.png',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="!isSuspend && isFirstTime == 'false' && kycApprove && haveCard && !profileStatus && domain_info.merchant_deposit != 1 && isNavActive"
          :link="{
            name: 'Transfer',
            path: '/transfer',
            imageicon: 'transfer-side-nav3.svg',
          }"
        >
        </sidebar-item>
          
        <sidebar-item
          v-if="!isSuspend && isFirstTime == 'false' && !profileStatus"
          :link="{
            name: 'Upload Documents (eKYC)',
            path: '/kyc',
            imageicon: 'kyc.png',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="!isSuspend && isFirstTime == 'false' && kycApprove && !profileStatus"
          :link="{
            name: 'Card Management',
            path: '/card-management',
            imageicon: 'card-management.png',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="!isSuspend"
          :link="{
            name: 'Settings',
            path: '/settings',
            imageicon: 'settings.png',
          }"
        >

          <sidebar-item
          v-if="isFirstTime == 'false'"
            :link="{
              name: 'Edit Profile',
              path: '/edit-profile'
            }"
          >
          </sidebar-item>

          <sidebar-item
            :link="{
              name: 'Change Password',
              path: '/change-password'
            }"
          >
          </sidebar-item>

          <sidebar-item
            v-if="isFirstTime == 'false'"
            :link="{
              name: 'System Configuration',
              path: '/system-configuration'
            }"
          >
          </sidebar-item>
        </sidebar-item>

        <sidebar-item
          v-if="!isSuspend && isFirstTime == 'false' && kycApprove"
          :link="{
            name: 'Report',
            path: '/report',
            imageicon: 'report.png',
          }"
        >

          <sidebar-item
            :link="{
              name: 'Transaction',
              path: '/transaction'
            }"
          >
          </sidebar-item>
          <sidebar-item
            :link="{
              name: 'Deposit',
              path: '/deposit-report'
            }"
          >
          </sidebar-item>
          <sidebar-item
            v-if="isFirstTime == 'false' && kycApprove && haveCard && !profileStatus && domain_info.merchant_deposit != 1 && isNavActive"
            :link="{
              name: 'Transfer',
              path: '/transfer-report'
            }"
          >
          </sidebar-item>
        </sidebar-item>

        <!-- <sidebar-item
            v-if="isFirstTime == 'false' && kycApprove && haveCard && !profileStatus && domain_info.merchant_deposit != 1"
            :link="{
              name: 'Account',
              path: '/account',
              imageicon: 'account.svg',
            }"
          >
        </sidebar-item> -->

        <!-- <sidebar-item
          :link="{
            name: 'About',
            path: '/about',
            imageicon: 'about',
          }"
        >
        </sidebar-item> -->

        <sidebar-item
          v-if="!isSuspend && chat==1"
          :link="{
            name: 'Customer Service',
            path: '/cs',
            imageicon: 'cs.png',
          }"
        >
        </sidebar-item>

        <sidebar-item
          v-if="!isSuspend && chat==1"
          :link="{
            name: 'FAQ',
            path: '/faq',
            imageicon: 'faq.svg',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Logout',
            path: '/logout',
            imageicon: 'logout.png',
          }"
        >
        </sidebar-item>

      </template>

    </side-bar>
    <div class="main-content">
      <div @click="$sidebar.displaySidebar(false);">
        <div v-if="company_logo" class="d-none d-md-block top-logo-in-content">
          <img alt="Image placeholder" :src="`${publicPath}/img/rcp/${company_logo}.png`" class="mr-5 mt-2 company-logo">
        </div>
        
        <!-- <TopSideMenu class="mb-2"></TopSideMenu> -->
        <notifications position="top right"/>
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view ref="currentPage"></router-view>
        </fade-transition>

        <floating-chat-icon v-if="chat==1 && !visibleChat" icon @toggle-chat="toggleChat" :isActive="showChat"></floating-chat-icon>
        <floating-chat 
          :isFloating="true" 
          v-if="!visibleChat && showChat && chat==1" 
          @toggle-chat="toggleChat"
          @is-connect="isReadyToChat">
        </floating-chat>
      </div>
    </div>

    <b-modal id="modal-scarm-alert" title="Reminder" @hide="$bvModal.hide('modal-scarm-alert')" hide-footer>
      <template #modal-title>
          {{ $t('Reminder') }}
      </template>
      <div class="mt-4 text-center">
        <img alt="Image placeholder" :src="`${publicPath}/img/rcp/scam_alert.gif`" class="w-100" style="max-width: 320px;"/>
        <h2>Scam Alert</h2>
        <p class="mx-4"> Members of the public are reminded not to disclose your personal identification numbers (PIN) and passwords to third parties under any circumstance. RCPAY LIMITED will NEVER request for personal financial information or confirmation of Mastercard transactions through telephone calls, emails or SMS. Please do not respond to such query. <br> Do NOT reveal your personal financial information. </p>
        <button class="btn btn-orange my-4" @click="$bvModal.hide('modal-scarm-alert')">{{ $t('I understand') }}</button>
      </div>
      
  </b-modal>
  </div>
</template>
<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';
  import Common from "@/services/Common";
  import Member from "@/services/Member";
  import TopSideMenu from "@/components/Navbar/TopSideMenu";
  import FloatingChatIcon from '@/components/Chat/FloatingChatIcon.vue';
  import FloatingChat from "@/components/Chat/Chat.vue";
  import { FadeTransition } from 'vue2-transitions';
  

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  export default {
    title: 'Dashboard - RCP',
    data(){
      return {
        publicPath      : process.env.BASE_URL!='/'?process.env.BASE_URL:'',
        translation     : process.env.VUE_APP_TRANSLATION,
        company_logo    : '',
        domain_info     : {},
        list_logo       : JSON.parse(process.env.VUE_APP_COMPANY_LOGO),
        chat            : process.env.VUE_APP_CHAT,
        domain          : process.env.VUE_APP_DOMAIN,
        isDebug         : process.env.VUE_APP_DEBUG_MODE,
        userListDebug   : process.env.VUE_APP_LIST_USER_DEBUG,
        suspendDomain   : process.env.VUE_APP_SUSPEND_DOMAIN,
        isSuspend       : false,
        isNavActive     : false,
        visibleChat     : false,
        showChat        : false,
        kyc_data:{
          poi:[
            {
              "id":1,
              "name":"Identity card",
              "list":[{"type":2},{"type":3}]
            },
            {
              "id":2,
              "name":"Passport",
              "list":[{'type':4}]
            }
          ],
          poa:[
            {
              "id":1,
              "name":"Driving license",
              "list":[{"type":5},{"type":6}]
            },
            {
              "id":2,
              "name":"Utility bill",
              "list":[{'type':7}]
            },
            {
              "id":3,
              "name":"Bank statement",
              "list":[{'type':8}]
            }
          ]
        },
        isFirstTime : false,
        kycStatus:false,
        kycApprove:false,
        profileStatus:false,
        haveCard:false,
      }
    },
    components: {
      FadeTransition,
      TopSideMenu,
      FloatingChatIcon,
      FloatingChat
    },
    methods: {
      isReadyToChat(is_connect){
        this.$refs['currentPage'].isLoading = !is_connect;
      },
      toggleChat() {
        this.showChat = !this.showChat;
      },
      clickOutSide(evt){
       
      },
      initScrollbar() {
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          initScrollbar('sidenav');
        }
      },
      checkKyc(current){
        Common.getKyc().then(response => {
          const data = response.data.data;
          let approve = true;
          
          Object.keys(this.kyc_data).forEach((key) => {
            const doc_list = this.kyc_data[key][current[key]].list;
            for (let i = 0; i < doc_list.length; i++) {
              if(data.kyc[doc_list[i].type].status!=1){
                approve=false;
              }
            }
          });

          if(approve){
            this.kycApprove = true;
            this.kycStatus = false;
          }
          else{
            this.kycStatus = true;
          }

          this.checkRedirect();
          
        }).catch(err => {
          this.checkError(err);
        });
      },
      checkRedirect(){
        this.init = localStorage.getItem("init");
        if(this.isSuspend){
          localStorage.removeItem("init");
          this.$router.push({ path: '/suspend' });
        }
        else{
          if(this.init=="true"){
            this.$bvModal.show('modal-scarm-alert');
          }

          if(this.isFirstTime != "false"){
            localStorage.removeItem("init");
            if(this.$route.name!="change-password"){
              this.$router.push({ path: '/change-password' });
            }
          }
          else if(this.profileStatus){
            if(this.$route.name!="edit-profile"){
              this.$router.push({ path: '/edit-profile' });
            }
          }
          else if(this.kycStatus && !this.profileStatus){
            localStorage.removeItem("init");
            if(this.$route.name!="kyc"){
              this.$router.push({ path: '/kyc' });
            }
          }
          else if(!this.haveCard && this.kycApprove && this.$route.name!="card-management" && this.$route.name!="card-management-2" && this.$route.name!="order-card" && this.$route.name!="fill-in-details" && this.$route.name!="complete"){
            localStorage.removeItem("init");
            this.$router.push({ path: '/card-management' });
          }
          else if(!this.profileStatus && this.kycApprove && this.haveCard && this.init == "true"){
            localStorage.removeItem("init");
            this.$router.push({ path: '/transaction' });
          }
          else if(this.domain_info.merchant_deposit == 1 && this.$route.name=="deposit"){
            localStorage.removeItem("init");
            this.$router.push({ path: '/' });
          }
          else{
            localStorage.removeItem("init");
          }
        }
        
      },
      getProfile(){
        this.isFirstTime = localStorage.getItem("isFirstTime");

        Common.profile().then(response => {
          if(response.status == 200 && response.data.success){
            const profile = response.data.data;
            
            if(this.suspendDomain.includes(profile.domain)){
              this.isSuspend = true;
            }

            if(this.userListDebug.includes(profile.username) && this.isDebug==1){
              this.isNavActive = true;
            }

            if(profile.domain.indexOf(window.location.hostname)==-1 && this.domain==1){
              localStorage.removeItem("token");
              localStorage.removeItem("isFirstTime");
              window.parent.location.href = profile.domain;
            }
            else{

              if(profile.fullname==null){
                this.profileStatus = true;
              }
              else{
                this.profileStatus = false;
              }
              
              if(this.translation == 1 && profile.language && profile.language!=null){
                localStorage.setItem("lang",profile.language);
                localStorage.setItem("lang-key",profile.language);
              }
              
              if(profile.doc_type!=null && profile.poa_type!=null && profile.doc_type>0 && profile.poa_type>0){
                this.checkKyc({"poi":parseInt(profile.doc_type)-1,"poa":parseInt(profile.poa_type)-1});
              }
              else{
                this.kycStatus=true;
                this.checkRedirect();
              }
            }
          }
        }).catch(err => {
          this.checkError(err);
        });
      },
      inIframe:function(){
          try {
              return window.self !== window.top;
          } catch (e) {
              return true;
          }
      },
      setChatVisible(page){
          if(page == "customer-service"){
            this.visibleChat =true;
          }
          else{
            this.visibleChat =false;
          }
      },
      checkError(err){
        try{
          const response = err.response;            
          if(response.status == 401 && response.data.name == "Unauthorized"){
                  localStorage.removeItem("token");
                  localStorage.removeItem("member");
                  localStorage.removeItem("isFirstTime");
                  this.$router.push({ 
                    path: '/login', 
                  });
                }
          else{
            if(localStorage.getItem("token")){
              var msg = (response.data)?response.data.message:err.message;
              this.$notify({
              type: 'warn',
              text: msg
              })
            }
          }
        }catch(e){
          console.log(err);
        }
      }
    },
    mounted() {

      Object.keys(this.list_logo).forEach((key) => {
        if(window.location.hostname.indexOf(key)!=-1){
          this.company_logo = this.list_logo[key];
        }
      });

      Member.domain().then((response)=>{
          if (response.status == 200) {
              this.domain_info = response.data.data;
          }
      }).catch((err) => {
          this.checkError(err);
      });

      Common.card_active().then(response => {
        if(response.status==200){
          const card = response.data.data;
          for(let i=0;i<card.length;i++){
            if(card[i]['status']==1 || card[i]['status']==3 || card[i]['status']==4){
              this.haveCard = true;
            }
          }
          this.getProfile();
        }
      });

      this.initScrollbar();
      this.setChatVisible(this.$route.name);
    },
    watch:{
        $route (to, from){
            this.setChatVisible(to.name);
        }
    } 
  };
</script>
<style lang="scss">
</style>
